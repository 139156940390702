import { useMutation } from 'react-query'
import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { IncidentExportRequest } from './dto/IncidentExportRequest'

export const useIncidentExportQuery = (
	incidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (response: Response) => void
		onError?: (error: unknown) => void
	} = {},
) => {
	return useMutation(
		(incidentExportRequest: IncidentExportRequest) =>
			incidentRepository.exportIncidents(incidentExportRequest),
		{
			onSuccess,
			onError,
		},
	)
}
