import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CreateIncidentActivityDTO } from 'src/modules/incidents/application/dto/CreateIncidentActivityDTO'
import { IncidentActivityDTO } from 'src/modules/incidents/application/dto/IncidentActivityDTO'
import { IncidentActivityFindRequest } from 'src/modules/incidents/application/dto/IncidentActivityFindRequest'
import { IncidentActivityFindResponse } from 'src/modules/incidents/application/dto/IncidentActivityFindResponse'
import { IncidentDetailClosedDTO, IncidentDetailDTO } from 'src/modules/incidents/application/dto/IncidentDetailDto'
import { responseToDto } from 'src/modules/incidents/infrastructure/CorporativeIncident'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { AuthContext, useLang, useTranslator } from 'ufinet-web-functions'

import { Loading, UfinetBox } from 'ufinet-web-components'
import {
	DetailTicketClosed,
	DetailTicketIncidentInfo,
	DetailTicketNotes,
	DetailTicketServiceInfo,
	DetailTicketVisitInfo,
} from './components'

const CorporateTicketDetailPage: FC = () => {
	const authData = useContext(AuthContext)
	const [incident, setIncident] = useState<IncidentDetailDTO>({} as IncidentDetailDTO)
	const [incidentClosed, setIncidentClosed] = useState<IncidentDetailClosedDTO>({} as IncidentDetailClosedDTO)
	const [loadingIncident, setLoadingIncident] = useState(true)
	const { id } = useParams<{ id: string }>()
	const lang = useLang()
	const translate = useTranslator()
	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const [loadingActivities, setLoadingActivities] = useState(true)
	const [activities, setActivities] = useState<IncidentActivityDTO[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const rowsPerPage = 50

	const findCorporateIncident = async (incidentId: string) => {
		await incidentRepository
			.findCorporateIncident(incidentId)
			.then((res) => responseToDto(res, lang))
			.then((res) => {
				setIncident(res.incidentDetail)
				setIncidentClosed(res.incidentDetailClosed)
				setLoadingIncident(false)
			})
			.catch(() => {
				toast.error(translate('ERROR.DETAIL_TICKET.INCIDENT'))
				setLoadingIncident(false)
			})
	}

	useEffect(() => {
		if (id) {
			findCorporateIncident(id)
		}
	}, [id])

	const fetchIncidentActivities = async () => {
		if (!incident.incidentId) return

		setLoadingActivities(true)
		try {
			const request: IncidentActivityFindRequest = {
				incidentId: incident.externalId ? incident.externalId : null,
				externalId: incident.externalId ? null : incident.incidentId,
				pageNumber: 1,
				rowsPerPage,
			}

			const response: IncidentActivityFindResponse = await incidentRepository.findIncidentActivities(request)

			if (response.status === 204) {
				setActivities([])
				setTotalPages(1)
			} else {
				setActivities(response.data)
				setTotalPages(Math.ceil(response.data.length / 5))
				setCurrentPage(1)
			}
		} catch (error) {
			toast.error(translate('ERROR.FETCHING_ACTIVITIES'))
		} finally {
			setLoadingActivities(false)
		}
	}

	useEffect(() => {
		if (incident.incidentId) {
			fetchIncidentActivities()
		}
	}, [incident])

	const handlePageChange = (newPage: number) => {
		if (newPage !== currentPage) {
			setCurrentPage(newPage)
		}
	}

	const handleCreateIncidentActivity = async (subject: string, description: string) => {
		if (!id) return

		const newActivity: CreateIncidentActivityDTO = {
			incidentId: incident.externalId ? incident.externalId : null,
			externalId: incident.externalId ? null : id,
			subject: subject,
			description: description,
		}

		try {
			await incidentRepository.createIncidentActivity(newActivity)
			toast.success(translate('TICKET_DETAILS.ACTIVITY_CREATED_SUCCESS'))
			fetchIncidentActivities()
		} catch (error) {
			toast.error(translate('TICKET_DETAILS.ACTIVITY_CREATED_ERROR'))
		}
	}

	if (loadingIncident) {
		return (
			<UfinetBox className="detail-loading-container">
				<Loading userClasses="detail-loading" />
			</UfinetBox>
		)
	}

	return (
		<UfinetBox>
			<div>
				<DetailTicketIncidentInfo
					isCorporate
					ticketName={incident.ticketName}
					ticketId={incident.ticketId}
					creationDate={incident.creationDate}
					ticketStatus={incident.ticketStatus}
					lastStatus={incident.lastStatus}
					clientName={incident.customerName}
					reportType={incident.reportType}
					degradationType={incident.degradationType}
					incidentDescription={incident.incidentDescription}
					reportOrigin={incident.reportOrigin}
					clientTicketNumber={incident.customerTicketNumber}
					reportUser={incident.reportUser}
					notificationGroup={incident.notificationGroup}
				/>

				<DetailTicketServiceInfo
					administrativeCode={incident.administrativeCode}
					originCountryName={incident.originCountryName}
					destinationCountryName={incident.destinationCountryName}
					serviceType={incident.serviceType}
					transmissionMedium={incident.transmissionMedium}
					bandwidthCapacity={incident.bandwidthCapacity}
					vlanClient={incident.vlanClient}
					publicIp={incident.publicIp}
				/>

				{(incident.ticketStatusId === 912790005 || incident.ticketStatusId === 4) && (
					<DetailTicketClosed
						resetDate={incidentClosed.resetDate}
						closeDate={incidentClosed.closeDate}
						solutionType={incidentClosed.solutionType}
						primaryCause={incidentClosed.primaryCause}
						secondaryCause={incidentClosed.secondaryCause}
						confirmedWith={incidentClosed.confirmedWith}
						latitudeLongitudeFailure={incidentClosed.latitudeLongitudeFailure}
						additionalComments={incidentClosed.additionalComments}
						attributableTo={incidentClosed.attributableTo}
						discountTime={incidentClosed.discountTime}
						discountReason={incidentClosed.discountReason}
						rfoSentDate={incidentClosed.rfoSentDate}
						rfoSummary={incidentClosed.rfoSummary}
					/>
				)}

				<DetailTicketVisitInfo
					visitDate={incident.visitDate}
					visitContactName={incident.visitContactName}
					visitContactPhone={incident.visitContactPhone}
					visitRequirements={incident.visitRequirements}
				/>

				<DetailTicketNotes
					activities={activities}
					loading={loadingActivities}
					currentPage={currentPage}
					ticketStatusId={incident.ticketStatusId}
					onPageChange={handlePageChange}
					totalPages={totalPages}
					onCreateComment={handleCreateIncidentActivity}
					isTicketInProcess={!incident.ticketId}
				/>
			</div>
		</UfinetBox>
	)
}

export { CorporateTicketDetailPage }
